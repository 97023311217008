<template>
  <!-- forced to split div up to prevent report page template to not shows up -->
  <div class="flex-container non-scrollable">
    <page-title value="Facturation"/>
    <div class="flex-container non-scrollable">
      <DataTable ref="dt" :value="services" :paginator="true" class="p-datatable-customers" :rows="constants.defaultRowNumber"
               dataKey="id" :rowHover="true" v-model:filters="filters" filterDisplay="menu" selection-mode="single"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :currentPageReportTemplate="$t('datatable.currentPageReportTemplate', {first: '{first}', last: '{last}', totalRecords: '{totalRecords}', target: $t('datatable.target.services')})"
               :rowsPerPageOptions="constants.rowsPerPageOptions" scrollable scroll-height="flex" @rowSelect="clickService">
      <template #header>
        <div class="p-d-flex flex">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" :placeholder="$t('search')" />
          </span>
        </div>
      </template>
      <template #empty>
        {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
      </template>
      <template #loading>
        {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
      </template>
      <Column field="mission.jsonMission.jobTitle" :header="$t('mission').toUpperCase()" sortable filterField="mission.jsonMission.jobTitle" sortField="mission.jsonMission.jobTitle" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('mission') }}</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('mission.jsonMission.jobTitle')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="mission.clientContract.name" :header="$t('client').toUpperCase()" sortable filterField="mission.clientContract.name" sortField="mission.clientContract.name" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('client') }}</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('mission.clientContract.name')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="label" :header="$t('service').toUpperCase()" sortable filterField="label" sortField="label" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('service') }}</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('label')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="status" :header="$t('etat').toUpperCase()" sortable filterField="status" sortField="status" :showFilterMatchModes="false" style="min-width:10rem" :show-apply-button="false" :show-clear-button="false">
        <template #body="{data}">
          <Chip :class="'custom-chip-' + data.status" :label="getStatusTranslation(data.status).toUpperCase()">
          </Chip>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('etat') }}</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('status')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <Chip :class="'custom-chip-' + slotProps.option.value" :label="getStatusTranslation(slotProps.option.value).toUpperCase()"></Chip>
            </template>
            <template #value="slotProps">
              <div>
                <span>{{ slotProps.value ? slotProps.value.map((v) => getStatusTranslation(v)).join(', ') : 'ETAT' }}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="date" :header="$t('date').toUpperCase()" sortable filterField="date" sortField="date" :showFilterMatchModes="false">
        <template #body="{data}">
          {{ getDate(data.month, data.year) }}
        </template>
      </Column>
    </DataTable>
  </div>
  </div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import { mapGetters, mapState } from 'vuex'
import PageTitle from "@/components/PageTitle.vue";
export default {
  name: "PorteTableauServices",
  components: {PageTitle},
  data() {
    return {
      services: [],
      filters: {
        'global': {value: null, matchMode: null},
        'label': {value: null, matchMode: FilterMatchMode.IN},
        'mission.clientContract.name': {value: null, matchMode: FilterMatchMode.IN},
        'mission.jsonMission.jobTitle': {value: null, matchMode: FilterMatchMode.IN},
        'status': {value: null, matchMode: FilterMatchMode.IN},
        'date': {value: null, matchMode: FilterMatchMode.IN},
      }
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants
    }),
    ...mapGetters({
      me: 'auth/getMe',
      independent: 'independent/getIndependent',
    })
  },
  async created () {
    // Facturation
    this.$store.state.misc.loading = true

    await this.$store.dispatch('independent/getOneIndependentByUser', this.me.id)
    this.services = await this.$store.dispatch('service/getAllServicesByIndependent', this.independent.id)

    this.$store.state.misc.loading = false
  },
  methods: {
    getDate (month, year) {
      return (month < 10 ? '0' + month : month) + '/' + year
    },
    commonFilter (field) {
      let array = this.services.map((s) => {
        let fields = field.split('.')
        let v = s
        fields.forEach((f) => {
          v = v[f]
        })
        return v
      })
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item || Math.abs(fa.value - item) === 0)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    getStatusTranslation (status) {
      switch (status) {
        case 'toInvoice':
          return 'À facturer'
        case 'invoicing':
          return 'En cours'
        case 'invoiced':
          return 'Facturé'
      }
      return ''
    },
    // eslint-disable-next-line no-unused-vars
    clickService (service) {
      // console.log(service)
    },
  }
}
</script>

<style scoped>
.custom-chip-toInvoice {
  background-color: #feffcc;
  color: #dbdf0c;
}
.custom-chip-invoicing {
  background-color: #cce4ff;
  color: #2E6BAB;
}
.custom-chip-invoiced {
  background-color: #ebffcc;
  color: #97C353;
}
</style>


